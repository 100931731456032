@import 'styles/includes.scss';

.RawHtml {
    $root: &;
    @extend %container-text;

    margin-bottom: 48px;

    @include media(l) {
        margin-bottom: 80px;
    }

    &__Facts {
        margin-top: 16px;
        padding: 8px 16px;
        background-color: $colorGrey200;

        @include media(l) {
            margin-top: 0;
            position: absolute;
            width: 212px;
            top: 42px;
            right: -220px;
        }

        h2,
        h3,
        h4,
        h5 {
            @extend %navigation-titles;
        }

        p,
        ul,
        ol {
            @extend %small-labels;
            margin-bottom: 16px;
        }

        a {
            color: $colorBlack70;
            text-decoration: underline;

            transition: color 200ms ease-out;
            &:hover {
                color: #000;
            }
        }

        li {
            @extend %body-copy-small;
            padding-left: 20px;
            position: relative;
            margin-bottom: 8px;
        }

        ol {
            list-style-position: inside;
            list-style-type: decimal;
            text-indent: -20px;
        }

        ol li {
            &::marker {
                color: $colorBlue;
                font-weight: bold;
                margin-right: 12px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        ul li {
            &:before {
                content: '';
                display: block;
                height: 6px;
                width: 6px;
                position: absolute;
                top: 8px;
                left: 0;
                background-color: $colorBlue;
            }
        }

        b {
            font-weight: bold;
        }
        i {
            font-style: italic;
        }
    }

    &__Expand {
        @extend %button-titles;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        color: $colorBlack70;

        &:after {
            content: '';
            display: inline-block;
            height: 12px;
            background-size: contain;
            transition: transform 0.2s;
            transform: translateX(8px);
            background-image: url('#{$basepath}svg/icon-plus.svg');
            width: 12px;
        }
    }

    &--Inner {
        transform: none;
        padding: 0;
        margin: 0;
    }

    &__Html {
        .RawHtml--Expandable & {
            max-height: 200px;
            overflow: hidden;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 80px;
                width: 100%;
                display: block;
                background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 2%,
                    #ffffff 99%
                );
            }
        }

        h1 {
            @extend %h1;
        }

        h2 {
            @extend %h2;
        }

        h3 {
            @extend %h3;
        }

        h4 {
            @extend %h4;

            + ul,
            + ol {
                padding-top: 16px;
                margin-top: 8px;
                margin-bottom: 40px;
                border-top: 2px solid rgba($colorBlack, 0.12);
            }
        }

        h5 {
            @extend %h5;
        }

        h2,
        h3,
        h4,
        h5 {
            scroll-margin-top: 2em;

            a {
                text-decoration: none;
                border: none;
            }
        }

        a {
            color: $colorBlack70;
            text-decoration: none;
            scroll-margin-top: 2em;
            border-bottom: 1px solid #000;
            transition: color 200ms ease-out;

            &:hover {
                color: #000;
            }

            // Add external link styling if href containes visitsweden
            &:not([href*='visitsweden']),
            &[href*='corporate.visitsweden'],
            &[href*='traveltrade.visitsweden'] {
                line-height: 1.1;

                &::after {
                    content: '';
                    display: inline-block;
                    min-width: 16px;
                    min-height: 16px;
                    margin-left: 3px;
                    margin-right: 1px;
                    background-image: url(#{$basepath}svg/icon-external-link.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: relative;
                    bottom: -1.5px;

                    .RawHtml--WhiteOnBlue & {
                        background-image: url(#{$basepath}svg/icon-external-link-white.svg);
                    }
                }
            }
            // Remove external link styling if href starts with #
            &[href^='\#'] {
                padding-right: 0;

                &::after {
                    display: none;
                }
            }
            &[href*='document-proxy'] {
                &::after {
                    background-image: url(#{$basepath}svg/icon-download.svg);

                    .RawHtml--WhiteOnBlue & {
                        background-image: url(#{$basepath}svg/icon-download-white.svg);
                    }
                }
            }

            .RawHtml--WhiteOnBlue & {
                color: #fff;
                border-color: #fff;
            }
        }

        // a[href$='.pdf'],
        // a[href$='.docx'],
        // a[href$='.ppt'],
        // a[href$='.pptx'],
        // a[href$='.doc'] {
        //     padding-left: 20px;
        //     background-image: url('#{$basepath}svg/icon-download.svg');
        //     background-repeat: no-repeat;
        //     background-position: 0 center;
        // }

        p {
            @extend %body-copy;
            margin-bottom: 20px;
        }

        li {
            @extend %body-copy-small;
            padding-left: 20px;
            position: relative;
            margin-bottom: 8px;
        }

        ol {
            list-style-position: inside;
            list-style-type: decimal;
            text-indent: -20px;
        }

        ul,
        ol {
            margin-bottom: 20px;
        }

        ol li {
            &::marker {
                color: $colorBlue;
                font-weight: bold;
                margin-right: 12px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        ul li {
            &:before {
                content: '';
                display: block;
                height: 6px;
                width: 6px;
                position: absolute;
                top: 9px;
                left: 0;
                background-color: $colorBlue;
            }
        }

        b {
            font-weight: bold;
        }
        i {
            font-style: italic;
        }
    }

    &--InCampaignText {
        margin: 0;
        max-width: 900px;
        transform: none;

        h2 {
            margin-bottom: 12px;
        }

        h3,
        h4 {
            margin-bottom: 8px;
        }

        p {
            color: inherit;
        }

        p:not(:last-child) {
            margin-bottom: 24px;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}
